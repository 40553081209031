import { useContext, useEffect, useState } from "react"
import { Kyrse, KyrseDoc } from "../../types/Kyrse";
import { getKyrsById } from "../../services/local-data/kyrses";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { TestComponent } from "./Test";
import { AuthContext, createUser } from "../../core/auth";
import Cerificate from "../../components/certificate/certificate";
import { PaymentKurs } from "../../components/payments/payment_kurs";
import { User } from "../../types/auth";
import { getDateFormat } from "../../helpers/date-format";
import moment from 'moment';
import { addCertificate, getCertificate, getDocs, getTest, updateTest } from "../../services/firebase-store.service";
import { DataService } from "../../services/firebase.service";
import GoogleSlider from "../../components/GoogleSlider/google_slider";

const Kyrs = (props: RouteComponentProps) => {
    const [showSertificate, setshowSertificate] = useState<boolean>(false);
    const [downloadInx, setDownloadInx] = useState<number | null>(null);
    const { user, changeUser, setLoading } = useContext(AuthContext);
    const [startTestId, setStartTestId] = useState<string>('');
    const [showForm, setShowForm] = useState<boolean>(false);
    // const [showPayData, setShowPayData] = useState<boolean>(false);
    const [isPay, setIsPay] = useState<boolean>(false);
    const [testPoints, setTestPoints] = useState<number>(0);
    const [certDate, setCertDate] = useState<string>();
    const [hasCert, setHasCert] = useState<any>(null);
    const [userID, setUserID] = useState<string>('');
    const [kyrs, setKyrs] = useState<Kyrse>();
    const [_user, set_User] = useState<any>({});
    moment.locale('ua');
    // const { resultTest } = DataService();
    useEffect(() => { window.scrollTo(0, 0) }, []);
    useEffect(() => { setKyrs(getKyrsById(userID)) }, [userID]);

    useEffect(() => {
        if (user) {
            getCert()
        }
    }, [user, kyrs]);

    useEffect(() => {
        if (user) { set_User(user) }
    }, [user]);

    const getCert = async () => {
        const c = await getCertificate(user?.uid)
        if (c && c.certificates) {
            const hasC = c.certificates.find((c: any) => c.id == kyrs?.id)
            if (hasC) {
                setHasCert(hasC);
            }
        }
    }

    useEffect(() => {
        const params: any = props.match.params;
        if (params && params.id) {
            setUserID(params.id);
        }
    }, [props.match.params]);

    useEffect(() => {
        if (user && userID) {
            if (user.payments) {
                const pay = user.payments.find((r: any) => r?.shopOrderNumber == `${user.uid}-${userID}`)
                if (pay && pay.status != 'REJECTED') {
                    setIsPay(true);
                    const a = getDateFormat(new Date(pay.pay_date));
                    setCertDate(a);
                }
                else {
                    const pay2 = user.payments.find((r: any) => r?.shopOrderNumber == `${user.uid}-registration`)
                    if (pay2 && pay2.status != 'REJECTED') {
                        if (kyrs?.priceMembers == 0) {
                            setIsPay(true);
                        }
                    }
                }
            }
            if (kyrs?.price == 0) {
                setIsPay(true);
            }

            if (kyrs?.haveTest) {
                const _ans = user?.tests?.find((x: any) => x.testId == kyrs.id)
                if (_ans) {
                    setTestPoints(_ans.result + 40)
                }
            }

        }
    }, [user, userID, kyrs]);

    useEffect(() => {
        document.addEventListener('click', function (e) {
            if (e.shiftKey) {
                setshowSertificate(!showSertificate);
                return;
            }
        });
    }, []);

    const saveData = (e: any) => {
        e?.preventDefault();
        const u: User = Object.assign({}, _user);
        u.role = "basic";
        createUser(u, u.email || '', u.password)
            .then(_user => {
                if (_user) {
                    clickPay(_user as User)
                }
            })
    }

    const clickPay = (_user: User) => {
        _user && changeUser(_user as User);
        setLoading(true);
        set_User(_user);
        setTimeout(() => {
            const a = document.getElementById('Pay-kyrs');
            setLoading(false);
        }, 1000);
    }

    const changeValue = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, property: string) => {
        set_User({ ..._user, [property]: e.target.value } as User);
    }

    const geterateCertificate = async () => {
        const _date = certDate ? new Date(certDate as string).getTime() : new Date().getTime();


        const cert = {
            userID: user?.uid,
            id: kyrs?.id,
            name: `${user?.lastName} ${user?.firstName} ${user?.surName}`,
            profileId: user?.profileId,
            course: kyrs?.name,
            themes: kyrs?.themes,
            durationHours: kyrs?.durationHours,
            durationDays: kyrs?.durationDays,
            date: new Date(_date).getTime(),
            credits: kyrs?.credits
        }
        const a = await addCertificate(user?.uid, cert);
        if (a) {
            setHasCert(a);
        }
    }

    return (
        <div className="mx-4  home">
            <h4 className="text-center mb-4"> {kyrs?.name} </h4>

            {
                kyrs?.files.map((doc: KyrseDoc, i: number) => (
                    <div key={i} className="text-center pb-5 mb-5">
                        <object data={doc.value} type="application/pdf" width="60%" height="800px"> </object>
                    </div>
                ))
            }
            {
                kyrs &&
                <>
                    {!user && <>
                        {/* <button className="btn btn-primary mе-3" onClick={() => setShowForm(true)}>Оплатити курс </button> */}
                        {showForm &&
                            <div className="modal  mini-form-modal">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <div className='text-center'>Реєстрація</div>
                                        <span className="close" onClick={() => setShowForm(false)}>&times;</span>
                                    </div>
                                    <div className="modal-body">
                                        <form className='needs-validation' onSubmit={(e) => saveData(e)} >
                                            <div className="container mt-5">
                                                <div className="row">
                                                    <div className="col-md-12 col-sm-12">
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text" id="basic-addon1">Прізвище</span>
                                                            <input type="text"
                                                                required
                                                                value={_user?.lastName || ''}
                                                                className="form-control"
                                                                placeholder="Прізвище"
                                                                aria-label="lastName"
                                                                aria-describedby="basic-addon1"
                                                                onChange={(e) => changeValue(e, 'lastName')}
                                                            />
                                                        </div>
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text" id="basic-addon1">Ім'я</span>
                                                            <input type="text"
                                                                required
                                                                value={_user?.firstName || ''}
                                                                className="form-control"
                                                                placeholder="Ім'я"
                                                                aria-label="firstName"
                                                                aria-describedby="basic-addon1"
                                                                onChange={(e) => changeValue(e, 'firstName')}
                                                            />
                                                        </div>
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text" id="basic-addon1">По Батькові</span>
                                                            <input type="text"
                                                                required
                                                                value={_user?.surName || ''}
                                                                className="form-control"
                                                                placeholder="По батькові"
                                                                aria-label="surName"
                                                                aria-describedby="basic-addon1"
                                                                onChange={(e) => changeValue(e, 'surName')}
                                                            />
                                                        </div>
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text" id="basic-addon1">Email</span>
                                                            <input type="text"
                                                                required
                                                                value={_user?.email || ''}
                                                                className="form-control"
                                                                placeholder="email"
                                                                aria-label="email"
                                                                aria-describedby="basic-addon1"
                                                                onChange={(e) => changeValue(e, 'email')}
                                                            />
                                                        </div>
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text" id="basic-addon1">Пароль</span>
                                                            <input type="password"
                                                                required
                                                                value={_user?.password || ''}
                                                                className="form-control"
                                                                placeholder="Пароль"
                                                                aria-label="email"
                                                                aria-describedby="basic-addon1"
                                                                onChange={(e) => changeValue(e, 'password')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="mt-4">
                                                    <div className="text-right">
                                                        <button onClick={() => setShowForm(false)} className="btn btn-primary my-3 m-1"> Відміна </button>
                                                        <button type="submit" className="btn btn-success my-3 m-1"> Зберегти </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                    }

                    <div>
                        {kyrs.value}
                    </div>
                </>
            }
            {
                user &&
                <>
                    <div className="my-5">
                        {/* <GoogleSlider height={430} width={700} url={'gs://web-unapam.appspot.com/programs/8/Презентация_008.pptx'} /> */}
                        {
                            user && isPay &&
                            kyrs?.docs.map((doc: KyrseDoc, i: number) => (
                                <div key={i} className="doc-item mx-5 mb-4">
                                    <b>   {doc.name} </b>
                                    {downloadInx === i && <iframe key={i} src={doc.value} />}

                                    <button onClick={() => setDownloadInx(i)} type="button" className="btn btn-outline-success" data-mdb-ripple-color="dark">
                                        Завантажити
                                    </button>
                                </div>
                            ))
                        }
                        {
                            user && isPay && kyrs && (!kyrs?.haveTest || testPoints >= 70) &&

                                hasCert
                                ? <Cerificate
                                    name={hasCert.name}
                                    profileId={hasCert?.profileId}
                                    course={hasCert.course}
                                    themes={hasCert.themes}
                                    id={hasCert.id}
                                    durationHours={hasCert.durationHours}
                                    durationDays={hasCert.durationDays}
                                    date={new Date(hasCert.date)}
                                    credits={hasCert.credits}
                                    showSertificate={true}
                                    // name={`${user?.lastName} ${user?.firstName} ${user?.surName}`}
                                    // profileId={user?.profileId}
                                    // course={kyrs.name}
                                    // themes={kyrs.themes}
                                    // id={kyrs.id}
                                    // durationHours={kyrs.durationHours}
                                    // durationDays={kyrs.durationDays}
                                    // date={certDate ? new Date(certDate as string) : new Date()}
                                    // credits={kyrs.credits}
                                    // showSertificate={true}
                                />
                                : null
                        }

                        {
                            !isPay && kyrs &&
                            <div className="payment-form">
                                <PaymentKurs kyrs={kyrs} type={'kyrs'} hidden={false} />
                            </div>
                        }
                        {
                            kyrs && !isPay &&
                            <div className="d-block">

                                <div className="certificate-mini mt-5">
                                    <Cerificate
                                        name={`Фамілія Ім'я Побатькові`}
                                        course={kyrs.name}
                                        themes={kyrs.themes}
                                        durationHours={kyrs.durationHours}
                                        durationDays={kyrs.durationDays}
                                        date={new Date()}
                                        credits={kyrs.credits}
                                        showSertificate={true}
                                    />
                                </div>
                            </div>
                        }

                    </div>
                    {
                        kyrs?.haveTest && !(testPoints > 40) && isPay &&
                        <div className="start-test">
                            <button onClick={() => setStartTestId(userID)} type="button" className="btn btn-success" data-mdb-ripple-color="dark">
                                Розпочати тест
                            </button>
                        </div>
                    }
                </>
            }
            {
                (isPay && !hasCert && (!kyrs?.haveTest || testPoints >= 70)) &&
                <button className="btn btn-primary my-3" onClick={() => geterateCertificate()}>Згенерувати сертифікат</button>
            }
            {
                (!user) && <button className="btn btn-primary mе-3 mb-5" onClick={() => { setShowForm(true) }}>
                    {kyrs?.price == 0 ? 'Отримати ' : 'Оплатити '} курс
                </button>
            }

            {
                startTestId && <TestComponent
                    close={() => { setStartTestId(''); document.body.style.overflow = 'visible' }}
                    id={startTestId}
                />
            }
        </div >
    )
}

export default withRouter(Kyrs);