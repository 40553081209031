import { AdminMenuT } from "../../types/AdnimMenu"

const adminMenu: AdminMenuT = [
    {
        name: 'Emails', order: 1, icon: 'far fa-envelope', link: '/admin/emails',
        subItems: [
            { name: 'Надіслані', order: 1, icon: '', link: '/admin/emails/sent' },
          
        ]
    },
    {
        name: 'Заявки', order: 1, icon: 'fas fa-marker', link: '/admin/applications',
        subItems: [
            { name: 'На координатора', order: 1, icon: '', link: '/admin/applications/1' },
            { name: 'На посвідчення', order: 1, icon: '', link: '/admin/applications/2' },
            { name: 'На сертифікат В2', order: 1, icon: '', link: '/admin/applications/3' },
        ]
    },
    {
        name: 'Користувачі', order: 1, icon: 'fas fa-users', link: '/admin/users',
        subItems: [
            { name: 'Члени Організації', order: 1, icon: '', link: '/admin/users/1' },
            { name: 'Студенти', order: 1, icon: '', link: '/admin/users/2' },
        ]
    }
    ,
    {
        name: 'Оплати', order: 1, icon: 'fas fa-credit-card', link: '/admin/payments',
        subItems: [
            { name: 'Вступні внески', order: 1, icon: '', link: '/admin/payments/1' },
            { name: 'Оплачені курси', order: 1, icon: '', link: '/admin/payments/2' },
        ]
    },
    {
        name: 'Документи', order: 1, icon: 'far fa-folder-open', link: '/admin/documents',
        subItems: [
            { name: 'Сертифікати', order: 1, icon: '', link: '/admin/documents/1' },
            { name: 'Посвідчення', order: 1, icon: '', link: '/admin/documents/2' },
        ]
    },
    {
        name: 'Правнича допомога', order: 1, icon: 'fas fa-gavel', link: '/admin/law',

    }
]

export const getAdminMenu = () => adminMenu

