import { useContext, useEffect, useState } from "react"
import { RouteComponentProps, withRouter } from "react-router-dom";
import { AuthContext } from "../../../core/auth";
import { NavLink } from "react-router-dom";
import { getUsersData } from "../../../services/firebase-store.service";
import { User } from "../../../types/auth";
import { getComitetById } from "../../../services/local-data/comitets";
import { getDateFormat } from "../../../helpers/date-format";

export const UsersList = (props: RouteComponentProps) => {
    const [users, setUsers] = useState<[]>([]);
    const { user, setLoading } = useContext(AuthContext);
    const [dataType, setDataType] = useState<any>();

    useEffect(() => { window.scrollTo(0, 0) }, []);

    useEffect(() => {
        const params: any = props.match.params;
        setDataType(params.id);
    }, [props.match.params]);

    useEffect(() => {
        user && getData();
    }, [user, dataType])


    const getData = async () => {
        setLoading(true);
        let _users = await getUsersData();
        if (dataType > 0) {
            _users = _users.filter((user: User, i: number) => {
                const pay = user?.payments?.find((r: any) => r?.shopOrderNumber == `${user.uid}-registration`);
                if (dataType == 1) {
                    return (pay && pay.status == 'PAYED')
                }
                if (dataType == 2) {
                    return !(pay && pay.status == 'PAYED')
                }
            })
        }
        setUsers(_users);
        setLoading(false);
    }


    return (
        <div className="p-2">
            <h3>Користувачі</h3>

            <table className="table table-dark table-striped apll-table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">ПІБ</th>
                        <th scope="col">Роль</th>
                        <th scope="col">Комітет</th>
                        <th scope="col">Дата реєстрації</th>
                        <th scope="col">Сторінка</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        users.filter((x: User) => x.uid).map((user: User, i: number) =>
                            <tr key={i}>
                                <th scope="row">{i + 1}</th>
                                <td>{user.lastName} {user.firstName} {user.surName}</td>
                                <td>{user.role}</td>
                                <td>{getComitetById(user?.centralComm)?.name}</td>
                                <td>{getDateFormat(user.create)}</td>
                                <td> <NavLink className='nav-link' to={`/user/${user.profileId}`}>Переглянути</NavLink></td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}

export default withRouter(UsersList);