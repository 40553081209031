import { useContext, useEffect, useState } from "react"
import { addTest, getTest, updateUser } from "../../services/firebase-store.service"
import { Question, Test } from "../../types/Test";
import { AddQuestion } from "../../components/edit-mode/add-questions";
import { AuthContext } from "../../core/auth";
import { NotificationSvc } from "../../components/notification/Notification";
import { DataService } from "../../services/firebase.service";

export const TestComponent = ({ id, close }: any) => {
    const { user, changeUser, setLoading } = useContext(AuthContext);
    const [test, setTest] = useState<Test | null>(null);
    const [step, setStep] = useState<number>(1);
    const [testId, setTestId] = useState<number | null>(null);
    const [userAnswers, setUserAnswers] = useState<any>([]);
    const { onSuccess } = NotificationSvc();
    // ___________________________
    const isEditting = false;
    const { resultTest } = DataService();


    useEffect(() => { document.body.style.overflow = 'hidden' }, [0]);
    useEffect(() => { getData(); }, []);

    // const addingNewTest = () => {  addTest()}

    const getData = async () => {
        const _test = await getTest(id);
        setTest(_test as Test);
        if (user) {
            const index = user.tests?.findIndex((el: any) => el.testId == id);
            if (index >= 0) {
                setUserAnswers(user.tests[index].answers)
            }
        }
        // only for dev
        // addingNewTest()
    }

    const nextQuestion = () => {
        saveAnswer();
        setStep(step + 1);
    }

    const prevQuestion = () => setStep(step - 1);

    const saveAnswer = (result: number = 0) => {
        if (user) {
            const _user = Object.assign({}, user);
            if (!_user.tests) {
                _user.tests = [`${id}`];
                setTestId(0)
            }
            if (testId == null) {
                const index = _user.tests.findIndex((el: any) => el.testId == id);
                if (index >= 0) {
                    setTestId(index)
                } else {
                    _user.tests.push({ testId: id, answers: userAnswers, result });
                }
            }
            if (testId != null) {
                console.log('3');
                const index = _user.tests.findIndex((el: any) => el.testId == id);
                if (index >= 0) {
                    console.log('33');
                    _user.tests[testId] = { testId: id, answers: userAnswers, result };
                }
                updateUser(user?.uid, _user);
                changeUser(_user);
            }
        }
    }

    const selectAnswer = (question: number, answer: number) => {
        const _userAnswers = Object.assign([], userAnswers);
        _userAnswers[question] = (answer + 1);
        setUserAnswers(_userAnswers);
        setTest(test as Test);
    }

    const completeTest = async () => {
        const result = await resultTest(id, userAnswers);
        const last = userAnswers[userAnswers.length - 1] == 1 ? 10 : 0;
        saveAnswer(result.data + last);
        close();
        onSuccess('Test was saved successfully');
    }


    return (
        <div>
            {isEditting ? <AddQuestion testNumber='1' />
                : <div id="myModal" className="modal">
                    {
                        test?.questions.map((item: Question, i: number) =>
                            step == i + 1 && <div key={i} className="modal-content">
                                <div className="modal-header">
                                    <span className=""> Питання  {i + 1} ({test?.questions.length})</span>
                                    <span className="close" onClick={() => close()}>&times;</span>
                                </div>
                                <div className="modal-body">
                                    <p>{item.question}</p>
                                    {
                                        item.answers.map((ans: any, ii: number) =>
                                            <div key={ii} onClick={() => selectAnswer(i, ii)} className={`${userAnswers[i] == (ii + 1) ? 'anw-selected' : ''} answer-item`} >
                                                {ans}
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="modal-footer">
                                    <button disabled={i == 0} onClick={() => prevQuestion()} className="btn btn-primary my-3 ">
                                        <i className="fas fa-arrow-left mx-1"></i> Попереднє питання
                                    </button>
                                    {
                                        test?.questions.length == i + 1
                                            ? <button disabled={!userAnswers[i]} onClick={() => completeTest()} className="btn btn-primary my-3 ">
                                                Готово <i className="fas fa-check mx-1"></i>
                                            </button>
                                            : <button disabled={!userAnswers[i]} onClick={() => nextQuestion()} className="btn btn-primary my-3 ">
                                                Наступне питання <i className="fas fa-arrow-right mx-1"></i>
                                            </button>
                                    }

                                </div>
                            </div>
                        )
                    }
                </div>
            }
        </div>

    )

}